import { Pipe, PipeTransform } from '@angular/core';
import { ICreditsFormatter } from '../interfaces';

@Pipe({
  name: 'creditsFormatter',
})
export class CreditsFormatterPipe implements PipeTransform {
  transform(amountLabel: number | string, args: ICreditsFormatter): string {
    // transforing data to numericValue
    const amount =
      typeof amountLabel === 'string' ? parseFloat(amountLabel) : amountLabel;

    // return '' if not number
    if (isNaN(amount)) {
      return '';
    }

    if (args.credit) {
      const formattedAmount = this.formatAmount(amount, args.currencyCode);

      return formattedAmount;
    }

    const formattedAmount = this.formatAmount(amount, args.currencyCode);
    return `${args.currencySymbol} ${formattedAmount} ${args.currencyCode}`;
  }

  formatAmount(amount: number, currencyCode: string): string {
    switch (currencyCode) {
      case 'MXN':
        return amount.toLocaleString('es-MX', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'BRL':
        return amount.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'COP':
        return amount.toLocaleString('es-CO', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'PEN':
        return amount.toLocaleString('es-PE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'CLP':
        return amount.toLocaleString('es-CL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'VES':
        return amount.toLocaleString('es-VE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'USD':
        return amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case 'ARS':
        return amount.toLocaleString('es-AR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      default:
        return amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    }
  }
}
