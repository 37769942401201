import { Component, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import screenfull from 'screenfull';

@Component({
  selector: 'app-btn-full-screen',
  templateUrl: './btn-full-screen.component.html',
  styleUrls: ['./btn-full-screen.component.css'],
})
export class BtnFullScreenComponent implements OnInit {
  isFullScreen: boolean = false;
  isMobile: boolean = false;
  showButton: boolean = true;

  @HostListener('window:blur', ['$event'])
  onFocus(event: FocusEvent) {
    this.isFullScreen = false;
  }

  constructor(private deviceService: DeviceDetectorService) {
    const ua = navigator.userAgent;
    this.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      );
    const browser = this.deviceService.browser;
    const os = this.deviceService.os;
    if (browser === 'Safari' && os === 'iOS') {
      this.showButton = false;
    }
  }

  ngOnInit(): void {}

  toggleFullscreen() {
    screenfull
      .toggle()
      .then(() => {
        this.isFullScreen = screenfull.isFullscreen;
      })
      .catch((err) => console.error(err));
  }
}
