import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './core/interceptors/auth-interceptor.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { FormsModule } from '@angular/forms';

import {SharedModule} from './shared/modules/primeng.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RestartPasswordComponent } from './components/restart-password/restart-password.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ApiCore } from './config/api';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { CreditsInfoComponentComponent } from './components/credits-info-component/credits-info-component.component';
import { AuthModule } from './pages/auth/auth.module';
import { BtnFullScreenComponent } from './components/btn-full-screen/btn-full-screen.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { BannerModule } from './components/banner/banner.module';
import { RedirectComponent } from './components/redirect/redirect.component';
//import { SessionService } from '../auth/session.service';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// const config: SocketIoConfig = {
// 	url: ApiCore.BASE_URL + 'enter-game', // socket server url;
// 	options: {
// 		 transports: ['websocket'],
//     extraHeaders:{
//       Authorization: `Bearer ${localStorage.getItem('token')}`
//     }
    
// 	}
// }


@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    AppLayoutComponent,
    NotFoundComponent,
    LanguageSelectorComponent,
    RestartPasswordComponent,
    MainLayoutComponent,
    BtnFullScreenComponent,
    WalletComponent,
    RedirectComponent,
    //CreditsInfoComponentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es-MX',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule,
    BannerModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
