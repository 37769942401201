<p-toast position="bottom-center" key="te"></p-toast>
<form [formGroup]="restoreForm" autocomplete="off" (ngSubmit)="sendNewPassword()">
    <div style="display: flex; width: 100%; height:100%; justify-content: center; justify-items: center; padding-top: 80px;">
        <div style="flex-direction: column; justify-content: center;  width: 100%; display: flex; align-items: center;">
            <img [src]="operatorLogo" [routerLink]="['/']" style='width: 300px; height: 90px; cursor: pointer;'>
            <label style="margin-top: 20px;" class="header_title"> {{'restore.title' | translate}} </label>
            <label style="margin-top: 20px;" class="header_subtitle"> {{'restore.subTitle' | translate}} </label>
            <div class="float_div">
                <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'restore.new_password' | translate}}</span>

                        <span class="p-input-icon-right">
                          <span class="p-input-icon-left">
                            <i ngClass="pi-key" class="pi" style=" margin-top: -10px;"></i>
                          </span>
                        <i [ngClass]="!showPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showPassword = !showPassword" style="cursor:pointer"></i>
                        <input (input)="validatePassword()" style="padding: 0.429rem 2.2rem;" maxlength="16" formControlName="password" [type]="showPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'restore.new_password_placeholder' | translate}}">
                        </span>

                        <small style="color: #FC6161 ;" *ngIf="getPassword?.errors" class="p-invalid">{{errors}}</small>
                        <small style="color: #FC6161 ;" *ngIf="isDuplicated && !getPassword?.errors" class="p-invalid">{{errorDuplicated}}</small>
                    </div>
                    <div style="width: 90%; display: flex; flex-direction: column;">
                        <label style="font-size: 12px; color: gainsboro;"> {{'errors.min8' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;"> {{'errors.min1' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;"> {{'errors.minUpper' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;"> {{'errors.minLow' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;"> {{'errors.dupPass' | translate}}</label>
                    </div>
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'restore.confirm_password' | translate}}</span>

                        <span class="p-input-icon-right">
                          <span class="p-input-icon-left">
                            <i ngClass="pi-key" class="pi" style=" margin-top: -10px;"></i>
                          </span>
                        <i [ngClass]="!showConfirmPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showConfirmPassword = !showConfirmPassword" style="cursor:pointer"></i>
                        <input (input)="validateSamePassword()" style="padding: 0.429rem 2.2rem;" maxlength="16" formControlName="confirmedPassword" [type]="showConfirmPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'restore.confirm_password_placeholder' | translate}}">
                        </span>

                        <!-- <input (input)="validateSamePassword()" formControlName="confirmedPassword" style="margin-top: 5px;" type="password" class="p-inputtext" placeholder="{{'restore.confirm_password_placeholder' | translate}}" pInputText /> -->
                        <small style="color: #FC6161 ;" *ngIf="!isSamePassword && !getConfirmedPassword?.pristine" class="p-invalid">{{'restore.samePassword' | translate}}</small>
                    </div>

                    <button type="submit" [disabled]="restoreForm.invalid || errors || isDuplicated" style="margin-top:20px; margin-bottom: 30px; width: 90%;" pButton label="{{'common.continue' | translate}}"></button>
                </div>
            </div>

        </div>
    </div>
</form>