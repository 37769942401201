<div style=" box-shadow: 0px 10px 15px rgb(26, 26, 26);">
  <!-- Mi credits card --> 
  <p-card 
  (click)="buyCredits();" 
  *ngIf="!showLoadSkeleton.wallet" 
  [style]="{'background-color':'#E6BF4B','color':'#112A46', 'padding':'0px 0px','display':'flex','justify-content':'center','align-items':'center', 'min-width': ' 200px'}" 
  styleClass="cursor-pointer web-wallet-card shadow-3 fadein animation-duration-300 wallet-card">
          <div class="flex flex-row pl-0 justify-content-center">
              <div class="mr-4 flex align-items-center" style="margin-right: 0px !important; min-width: 45px;">
                  <img [src]="'../assets/images/money.png'" style='width: 45px; height: 45px;'>
              </div>
              <div class="flex-col p-fluid ml-2 text-overflow-ellipsis" style="width: auto; max-width: 150px; text-overflow: ellipsis; overflow-x: hidden;">
                  <div class="p-inputgroup mb-1" style="width: fit-content;">
                      <span >{{'lobby.my_credits' | translate}}</span>
                  </div>
                <div class="p-inputgroup" style="width: fit-content;">
                      <span style="font-size: large;"><strong>{{totalBalance | creditsFormatter:{currencyCode, currencySymbol, credit: true} }}</strong> </span>
                  </div>
              </div>
              
          </div>
  </p-card>
  <p-card (click)="buyCredits()" *ngIf="!showLoadSkeleton.wallet" [style]="{'background-color':'#E6BF4B','color':'#112A46', 'padding':'0px 0px','height':'60px','display':'flex','justify-content':'space-around','align-items':'center'}" styleClass="mobile-wallet-card shadow-3 fadein animation-duration-300 wallet-card">
          <div class="flex flex-row pl-0 ">
              <div class="mr-4 flex align-items-center" style="margin-right: 0px !important; min-width: 40px;">
                  <img [src]="'../assets/images/money.png'" style='width: 40px; height: 40px;'>
              </div>
              <div class="grid p-fluid ml-2 pt-1" style="width: 150px;">
                  <div class="p-inputgroup">
                      <span style="font-weight: bold ;"><small>{{'lobby.my_credits' | translate}}</small></span>
                  </div>
                  <div class="p-inputgroup">
                      <span><strong *ngIf="totalBalance">{{totalBalance | number}}</strong> </span>
                  </div>
              </div>
              <!-- <div style="display: flex; align-self: center; ">
                  <button class="shadow-3" style="border-color:#0c9c08; background-color: #0c9c08; color: black; " pButton label="{{'lobby.cashier' | translate}}" (click)="buyCredits();"></button>
              </div> -->
          </div>
  </p-card>
  <!-- show load Skeleton -->
  <div *ngIf="showLoadSkeleton.wallet" class="fadein animation-duration-200 border-round border-1 surface-border p-2 surface-card" >
      <div class="flex justify-content-between">
          <div class="flex">
              <p-skeleton shape="Square" size="3.1rem"></p-skeleton>
              <div class="pl-2 flex flex-column justify-content-around">
                  <p-skeleton width="6rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton width="6rem" styleClass="mt-1"></p-skeleton>
              </div>
          </div>
          <div class="flex align-items-center pr-2">
              <p-skeleton height="2.4rem" width="5rem"></p-skeleton>
          </div>
      </div>
  </div>
</div>