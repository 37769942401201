export class ToolsValidations {

  public static passRequirement = {
     passwordMinLowerCase: 1,
     passwordMinNumber: 1,
     passwordMinSymbol: 0,
     passwordMinUpperCase: 1,
     passwordMinCharacters: 8
   };
 
   public static patternUser = ['^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$']
   .map(item => item.toString())
   .join("");
 
   public static patternMail = ['[a-z0-9._%+-]+@[a-z0-9.-]+[.].[a-z]{1,4}$']
   .map(item => item.toString())
   .join("");
 
   public static patternUserFullName = ['^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+$']
   .map(item => item.toString())
   .join("");
 
   public static patternPassword = [
     `(?=([^a-z]*[a-z])\{${this.passRequirement.passwordMinLowerCase},\})`,
     `(?=([^A-Z]*[A-Z])\{${this.passRequirement.passwordMinUpperCase},\})`,
     `(?=([^0-9]*[0-9])\{${this.passRequirement.passwordMinNumber},\})`,
     `[A-Za-z\\d]{${this.passRequirement.passwordMinCharacters},}`
   ]
   .map(item => item.toString())
   .join("");
 
   public static patternLowerCase = [
     `(?=([^a-z]*[a-z])\{${this.passRequirement.passwordMinLowerCase},\})`
   ]
   .map(item => item.toString())
   .join("");
 
   public static patternUpperCase = [
     `(?=([^A-Z]*[A-Z])\{${this.passRequirement.passwordMinLowerCase},\})`
   ]
   .map(item => item.toString())
   .join("");
 
   public static patternNumber = [
     `(?=([^0-9]*[0-9])\{${this.passRequirement.passwordMinLowerCase},\})`
   ]
   .map(item => item.toString())
   .join("");
 }