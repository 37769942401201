<button
  *ngIf="isMobile && showButton"
  (click)="toggleFullscreen()"
  style="
    background-color: transparent;
    border: none;
    color: white;
    font-size: 22px;
    cursor: pointer;
    padding: 5px;
    margin-left: 3px;
  "
>
  <span *ngIf="!isFullScreen" class="fa-solid fa-maximize"></span>
  <span *ngIf="isFullScreen" class="fa-solid fa-minimize"></span>
</button>
