<p-toast position="top-center" key="tc"></p-toast>
<form [formGroup]="recoverForm" (ngSubmit)="getCodeRecoverPassword()">
    <div style="display: flex; width: 100%; height:100%; justify-content: center; justify-items: center; padding-top: 80px;">
        <div style="flex-direction: column; justify-content: center;  width: 100%; display: flex; align-items: center;">
            <img [src]="operatorLogo" [routerLink]="['/']" style='width: 300px; height: 90px; cursor: pointer;'>
            <label style="margin-top: 20px;" class="header_title"> {{'recover.title' | translate}} </label>
            <label *ngIf="!codeSended" style="margin-top: 20px;" class="header_subtitle"> {{'recover.subTitle' | translate}} </label>
            <label *ngIf="codeSended" style="margin-top: 20px;" class="header_subtitle"> {{'recover.check_email' | translate}} </label>

            <div *ngIf="!codeSended" class="float_div">
                <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'signin.email' | translate}}</span>
                        <input style="margin-top: 5px;" formControlName="email" type="email" class="p-inputtext" placeholder="{{'signin.email_placeholder' | translate}}" pInputText />
                        <span class="p-error" *ngIf="!getEmail?.valid && !getEmail?.pristine">{{'recover.invalid_email'| translate}}</span>
                    </div>

                    <button [disabled]="!getEmail?.valid" type="submit" style="margin-top:20px; margin-bottom: 30px; width: 90%;border-radius: 2rem;" pButton label="{{'recover.send' | translate}}"></button>
                </div>
            </div>
            <a style="margin-top: 20px; font-size: 15px;cursor: pointer;" routerLink="../login" class="flex underline_link">{{'recover.back_to_login' | translate}}</a>
        </div>
    </div>
</form>
