import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileMenuService {

  private menuItems = new BehaviorSubject<MenuItem[]>([]);

  constructor() { }

  setData(newData: MenuItem[]): void {
    this.menuItems.next(newData);
  }

  getData(): Observable<MenuItem[]> {
    return this.menuItems.asObservable();
  }

}
