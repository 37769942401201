import { Component, OnInit, AfterContentInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SessionService } from './core/auth/session.service';
import { ApiService } from './core/http/api.service';
import { SetTitleService } from './shared/services/title.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
   menuMode = 'sidebar';

    layout = 'blue';

    theme = 'blue';

    ripple!: boolean;

    colorScheme = 'dark';
    subscription: Subscription;
    constructor(
      private primengConfig: PrimeNGConfig,
      private api: ApiService,
      private session: SessionService,
      public primeNGConfig: PrimeNGConfig,
      public translate: TranslateService,
      private readonly title: SetTitleService,
      private readonly casinoName: Title) {
        translate.addLangs(['es-MX']);
        translate.setDefaultLang('es-MX');

        this.subscription = this.translate.stream('primeng').subscribe(data => {
          this.primeNGConfig.setTranslation(data);
      });

      }


    async ngOnInit():  Promise<void> {
        this.primengConfig.ripple = true;
        this.ripple = true;
        await this.getOne();
        this.casinoName.setTitle(this.title.titleProject ? this.title.titleProject : this.session.getItem('casinoName') ?? '');
    }

    getOperatorConfig(): Promise<any> {
      return new Promise((resolve, reject) => {
        this.api.getCoorporateCasinoConfig().subscribe(res => {
          this.session.setItem("operatorLogo", res.data.logoCasinoUrl);
        });
      });
    }


  async getOne() {
    try {
      //this.imgUrl = (await this.testService.get('mewtwo')).sprites.back_shiny;
      this.api.getCoorporateCasinoConfig().subscribe(res => {
      this.session.setItem("operatorLogo", res.data.logoCasinoUrl);
      });
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }
}
