import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { Validators } from '@angular/forms';
import { ToolsValidations } from 'src/app/core/tools/tools.validators';
import { ApiCore } from 'src/app/config/api';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IError } from '../../../models/Auth/AuthInterfaces';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
  providers: [MessageService]
})
export class RecoverPasswordComponent implements OnInit {

  recoverForm = this.formBuilder.group({
    email: ['',[Validators.required,Validators.pattern(ToolsValidations.patternMail)]]
  })

  get getEmail(){ return this.recoverForm.get('email')}
  operatorLogo: string | null ="assets/images/no-photo-available.png";

  codeSended: boolean = false;

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private session: SessionService,
    private apiService: ApiService
  ) { }


  ngOnInit(): void {
    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    })
    this.getCoorporateConfiguration();
  }

  async getCoorporateConfiguration() {
    try {
      await this.apiService.getCoorporateCasinoConfig().subscribe(res => {
        this.session.setItem("operatorLogo", res.data.logoCasinoUrl);
        this.operatorLogo = res.data.logoCasinoUrl;
      });
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  public getCodeRecoverPassword(){
    const recoverPasswordEmail = {
      "email": this.getEmail?.value
    }

    if(!this.session.getItem('recoveryEmail'))
      this.session.removeItem('recoveryEmail')

    this.authService.recoverPassword(recoverPasswordEmail,ApiCore.RECOVER_PASSWORD).subscribe((resp)=>{

      if(resp.statusCode !== 200){
        this.messageService.add({key: 'tc',severity:'error', summary: `${resp.message}`});
        return;
      }
      if(!this.session.getItem('recoveryEmail')){
        this.session.setItem('recoveryEmail',recoverPasswordEmail.email!)
      }
      this.authService.recoverPasswordEmail = recoverPasswordEmail.email!;

      //this.router.navigate(['../app/auth/forgot-password']);
      this.codeSended = true;
      this.messageService.add({key: 'tc',severity:'success', summary: 'Se envió un nuevo código a tu correo electrónico'})

    },(error: HttpErrorResponse) => {
      const RecoverError: IError = error.error
      console.error(RecoverError);
      if(RecoverError.statusCode === 403){
        this.messageService.add({key: 'tc',severity:'error', summary: 'Verifica la información ingresada'})
        return
      }
      this.messageService.add({key: 'tc',severity:'error', summary: JSON.stringify(RecoverError.message)})
    })
  }
  HasCode(){
    let email = this.recoverForm.get("email")?.value ?? "";
    this.session.setItem('recoveryEmail',email);

    if(email == ''){
      this.messageService.add({key: 'tc',severity:'error', summary: 'Registra un correo electrónico para continuar'});
      return
    }
    this.router.navigate(['../app/auth/forgot-password']);
  }
}
