<p-sidebar [(visible)]="DisplaySideBar" [fullScreen]="true" (onHide)="CloseSideBar()" [blockScroll]="true">
    <div class="grid mr-1 ml-1">
        <div class="col-12 flex justify-content-center">
            <h2 class="mt-4">{{ title | translate}}</h2>
        </div>

        <div class="col-12 flex justify-content-center">
            <div id="divText" class="relative">
                <p-scrollPanel class="relative" [style]="{width: '100%', height: '50rem'}">
                    <p>{{ TerminosCondiciones | translate}}
                    </p>
                </p-scrollPanel>
            </div>
        </div>
    </div>
</p-sidebar>