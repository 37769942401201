import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from 'src/app/core/auth/session.service';
import { BannerService } from './banner.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  public bannerName = '';

  constructor(private session: SessionService, private bannerService: BannerService) {}

  ngOnInit(): void {
    this.bannerService.nameOfBanner.subscribe(data => this.bannerName = data);
    this.getCasinoName();
  }

  private getCasinoName() {
    const banner = this.bannerName
      ? this.bannerName
      : (this.session.getItem('casinoName') as string);
    this.bannerService.changeNameOfBanner(banner);
    // this.bannerName = this.bannerService.nameOfBanner;
    return;
  }
}
