import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from '../auth/jwt.service';
import { SessionService } from '../auth/session.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private session: SessionService, private jwt: JwtService, private readonly auth:AuthService) { }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const token = this.session.getItem('token')
    if (token && this.jwt.isValid(token)) {

      // return true;
      const data = await this.auth.verifyTokenExpiration(token)
      console.log({data});
      if (!!data) return true;
      this.router.navigate(['/app/auth/login']);
      console.error('Session token expired, please login again')
      return false;
    } else {
      this.router.navigate(['/app/auth/login']);
      console.error('Session token expired, please login again')
      return false;
    }
  }

}
