<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="te"></p-toast>
<form [formGroup]="recoveryForm" (ngSubmit)="setCode()">
    <div style="display: flex; width: 100%; height:100%; justify-content: center; justify-items: center; padding-top: 80px;">
        <div style="flex-direction: column; justify-content: center;  width: 100%; display: flex; align-items: center;">
            <img [src]="operatorLogo" [routerLink]="['/']" style='width: 300px; height: 90px; cursor: pointer;'>
            <label style="margin-top: 20px;" class="header_title"> {{'restorecode.title' | translate}} </label>
            <label style="margin-top: 20px;" class="header_subtitle"> {{'restorecode.subTitle' | translate}} </label>
            <label style="margin-top: 5px;" class="header_subtitle"> {{'restorecode.subTitle2' | translate}} </label>
            <div class="float_div">
                <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'restorecode.restore_code' | translate}}</span>
                        <input (input)="ValidateCurrentCode()" style="margin-top: 5px;" type="text" formControlName="recoveryCode" class="p-inputtext" placeholder="{{'restorecode.restore_code_placeholder' | translate}}" pInputText />
                        <span class="p-error" *ngIf="this.recoveryForm.get('recoveryCode')?.value != '' && this.recoveryForm.get('recoveryCode')?.invalid  && !isValideCode">{{'restorecode.invalid_code'| translate}}</span>
                    </div>

                    <button type="submit" [disabled]="!isValideCode" style="margin-top:20px; margin-bottom: 30px; width: 90%;" pButton label="{{'common.continue' | translate}}"></button>
                </div>
            </div>
            <a style="margin-top: 20px; font-size: 15px;cursor: pointer;" (click)="reSendCode()" class="flex underline_link">{{'restorecode.resend_code' | translate}}</a>
            <label style="margin-top: 20px;" class="footer_subtitle"> {{'restorecode.foote_subtitle' | translate}} </label>
            <div>
                <label style="margin-top: 0px !important; font-size: 15px;"> {{'restorecode.foote_subtitle2' | translate}} </label>
                <label style="margin-top: 0px !important; font-size: 15px; color: #0F8BFD; font-weight: bold;"> {{supportEmail}} </label>
            </div>

        </div>
    </div>
</form>
