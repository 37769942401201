import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '../auth/session.service';
// API
import { ApiCore } from '../../config/api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url: string = ApiCore.BASE_URL ;
  private options:any;

  constructor(
    private httpClient: HttpClient,
    private session: SessionService,
  ) {}

  /**
   * Get a list of objects/data from the API
   * @param endpoint Object/data name
   * @param queryParams  GET parameters to be included in the query
   */
  public list(endpoint: string, queryParams?: any): Observable<any> {
    this.options = {
      headers: new HttpHeaders()
      .set('Authorization', `Bearer ${this.session.getItem('token')}`)
    };
    let queryParamsString = '';
    if (queryParams) {
      queryParamsString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
    }
    return this.httpClient
      .get(`${this.url}${endpoint}?${queryParamsString}`, this.options)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

  /**
   * Get single object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public get(endpoint: string, id: string): Observable<any> {
    this.options = {
      headers: new HttpHeaders()
       .set('Authorization', `Bearer ${this.session.getItem('token')}`)
    };
    return this.httpClient
      .get(`${this.url}${endpoint}`, this.options)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

  /**
   * Create an object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   */
  public create(endpoint: string, object: any): Observable<any> {
    return this.httpClient
      .post(`${this.url}${endpoint}`, object)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

  /**
   * Send object to get another object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   * @returns 
   */
  public validateData(endpoint: string, object: any): Observable<any> {
    return this.httpClient
      .post(`${this.url}/${endpoint}`, object)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

  /**
   * Update an object/data using API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   * @param object Object/data as a JSON {key:value}
   */
  public update(endpoint: string, id: string, object: any): Observable<any> {
    return this.httpClient
      .put(`${this.url}/${endpoint}/${id}`, object)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

  /**
   * Update an object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   */
   public updateDirect(endpoint: string,  object: any): Observable<any> {
    return this.httpClient
      .put(`${this.url}${endpoint}`, object)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

   /**
   * Update an object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   */
    public updateDirectPost(endpoint: string,  object: any): Observable<any> {
      return this.httpClient
        .post(`${this.url}${endpoint}`, object)
        .pipe(map((data: any) => {
          this.session.setItem('token', data.token);
          return data;
        }), catchError(this.errorHandler));
    }

  /**
   * Delete an object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public delete(endpoint: string, id: string): Observable<any> {
    return this.httpClient
      .delete(`${this.url}/${endpoint}/${id}`)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }


     public GetOperatorConfig(): Observable<any> {
      this.options = {
      };
      return this.httpClient
        .get(`${this.url}operator/operator-config`, this.options)
        .pipe(map((data: any) => {
          this.session.setItem('token', data.token);
          return data;
        }), catchError(this.errorHandler));
    }

  /**
   * Catch the error and get the data from error property
   * @param error HttpErrorResponse object
   * @returns JSON object with the error property value
   */
   private errorHandler(error: HttpErrorResponse) {
    return throwError(() => error.error)
  }

  // Returns an observable
  public upload(endpoint: string, file: any):Observable<any> {

    // Create form data
    const formData = new FormData();
    // Store form name as "file" with file data
    formData.append("file", file, file.name);

    // Make http post request over api
    // with formData as req
    return this.httpClient.post(`${this.url}${endpoint}`, formData).pipe(map((data: any) => {
      this.session.setItem('token', data.token);
      return data;
    }), catchError(this.errorHandler));
  }

    // Returns an observable
    public uploadImageProfile(endpoint: string, file: any):Observable<any> {

      // Create form data
      const formData = new FormData();
      // Store form name as "file" with file data
      formData.append("file", file, file.name);

      // Make http post request over api
      // with formData as req
      return this.httpClient.put(`${this.url}${endpoint}`, formData).pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
    }

      /**
   * Get single object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public getGameURL(endpoint: string, id: string): Observable<any> {
    this.options = {
      headers: new HttpHeaders()
       .set('Authorization', `Bearer ${this.session.getItem('token')}`)
    };
    return this.httpClient
      .get(`${this.url}${endpoint}${id}`, this.options)
      .pipe(map((data: any) => {
        this.session.setItem('token', data.token);
        return data;
      }), catchError(this.errorHandler));
  }

  /**
   * Get single object/data from api
   * @param endpoint Object/data name
   * @returns Object to validate game session by user
   */
  public getGameSession(endpoint: string): Observable<any> {
    this.options = {
      headers: new HttpHeaders()
       .set('Authorization', `Bearer ${this.session.getItem('token')}`)
    };
    return this.httpClient
      .get(`${this.url}${endpoint}`, this.options);
  }

  public getCoorporateCasinoConfig():Observable<any>{
    return this.httpClient.get(`${this.url}operator/coorporate-config`);
  }

}
