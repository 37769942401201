import { EventEmitter, Injectable, Output } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ApiCore } from 'src/app/config/api';
import { SessionService } from 'src/app/core/auth/session.service';
interface SocketResponse {
  credits: number;
}

@Injectable({ providedIn: 'root' })
export class WalletSocketService extends Socket {
  @Output() outEven: EventEmitter<any> = new EventEmitter();
  private authToken: string = '';

  constructor(private session: SessionService) {
    super({
      url: `${ApiCore.BASE_URL}chatprueba`,

      options: {
        query: {
          token: session.getItem('token'),
        },
        extraHeaders: {
          authorization: `Bearer ${session.getItem('token')}`,
        },
      },
    });
    // this.authToken = session.getItem('token')
    this.listen();
    this.ioSocket.on('connect', () => {
      const token = this.session.getItem('token');
      this.ioSocket.connect();
      this.ioSocket.emit('join_wallet', token);
    });
  }

  listen = () => {
    this.ioSocket.on('updating_wallet', (res: any) => {
      // console.log('listening updating_wallet event', res);
      this.outEven.emit(res);
    });
    // this.ioSocket.on('update_user_wallet', (res:SocketResponse) => {
    //   // console.log('listening all socket', res.credits);
    //   this.outEven.emit(res.credits)
    // });
  };

  // emitWalletUpdate = (payload: { credits: number }) => {
  //   this.ioSocket.emit('wallet_update', payload)
  // }
  disconnectSocket = () => {
    this.ioSocket.disconnect();
    //podríamos salir de la wallet en el backend con el client?
  };

  connectSocket() {
    // this.ioSocket.disconnect();
    const token = this.session.getItem('token');
    this.ioSocket.connect();
    this.ioSocket.emit('join_wallet', token);
  }
}
