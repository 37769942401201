import { NgModule } from '@angular/core';

import { CreditsFormatterPipe } from '../pipes/credits-formatter.pipe';

@NgModule({
  imports: [],
  exports: [CreditsFormatterPipe],
  declarations: [CreditsFormatterPipe],
  providers: [],
})
export class CreditsFormatterModule {}
