import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';
import { ToolsValidations } from 'src/app/core/tools/tools.validators';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ApiCore } from '../../../config/api';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  recoveryForm = this.formBuilder.group({
    recoveryCode: ['',[Validators.required,Validators.minLength(64)]]
  })

  isValideCode:boolean = false;

  get getCode(){ return this.recoveryForm.get('recoveryCode')}
  operatorLogo: string | null ="assets/images/no-photo-available.png";
  supportEmail = "soporte@casino.com";

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private session: SessionService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    window.history.pushState('forward', null as any, window.location as any); 
    
    let urlParamaters =  this.router.url.split('?');
    if(urlParamaters.length > 1)
    {
      let email = urlParamaters[1].split('&')[0].replace("email=","");
      let code = urlParamaters[1].split('&')[1].replace("code=","");
      this.recoveryForm.get("recoveryCode")?.setValue(code);
      this.session.setItem('recoveryEmail',email);
      this.ValidateCurrentCode();
    }

    if(!this.session.getItem('recoveryEmail')) {
      this.router.navigate(['/#/app/auth/recover-password']);
    }

    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    })
    this.getCoorporateConfiguration();
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  setCode(){
    const code = this.getCode?.value;
    this.authService.recoverCode = code!;
    this.router.navigate(['../app/auth/restore-password']);
  }

  ValidateCurrentCode()
  {
    this.apiService.create("auth/validate-restore-code",{restoreCode:this.getCode?.value}).subscribe(res => {
      this.isValideCode = true;
      this.recoveryForm.get('recoveryCode')?.setErrors(null);
    },error => {
      this.isValideCode = false;
      this.recoveryForm.get('recoveryCode')?.setErrors({'incorrect': true});
    });
  }

  async getCoorporateConfiguration() {
    try {
      await this.apiService.getCoorporateCasinoConfig().subscribe(res => {
        this.supportEmail = res.data.contactEmail;
        this.session.setItem("operatorLogo", res.data.logoCasinoUrl);
        this.operatorLogo = res.data.logoCasinoUrl;
      });
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }

  reSendCode(){
      const recoverPasswordEmail = {
        "email": this.authService.recoverPasswordEmail
      }

      if(recoverPasswordEmail.email === ''){
        recoverPasswordEmail.email = this.session.getItem('recoveryEmail')!;
      }

      this.authService.recoverPassword(recoverPasswordEmail,ApiCore.RECOVER_PASSWORD).subscribe((resp)=>{

        if(resp.statusCode !== 200){
          this.messageService.add({key: 'te',severity:'error', summary: 'Error al generar el codigo'});
          return;
        }
        this.messageService.add({key: 'tc',severity:'success', summary: `Se envió un nuevo código a tu correo electrónico.`});
      },(error) => {
        console.log(error);
        this.messageService.add({key: 'te',severity:'error', summary: 'Error al generar el codigo'})
      })
  }
}
