import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { ApiCore } from 'src/app/config/api';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {
  private storageSubject = new BehaviorSubject<any | null>(null);

  constructor(private http: HttpClient) {
    window.addEventListener('storage', this.onStorageChange.bind(this));
  }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
    this.storageSubject.next({ key: key, value: value });
  }

  getItem(key: string) {
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  valueChanges(): Observable<string | null> {
    return this.storageSubject.asObservable();
  }

  private onStorageChange(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      const key = event.key ?? '';
      const newValue = event.newValue;
      this.storageSubject.next({ key: key, value: newValue });
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.onStorageChange.bind(this));
  }
  deleteUserGameSession(){
    //deleting game-session
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getToken()
      })
    };
    
    return this.http.delete<any>(ApiCore.BASE_URL + "game-session/", httpOptions).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));
  }
  private errorHandler(error: HttpErrorResponse) {
    return throwError(() => error.error)
  }
  private getToken(): string {
    const token = this.getItem('token');
    if (token == null) {
      return '';
    } else {
      return token;
    }
  }
}
