import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { ApiCore } from '../../config/api';
import { JwtService } from './jwt.service';
import { SessionService } from './session.service';
import { IRecoverPassword } from 'src/app/models/Auth/AuthInterfaces';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl: string = ApiCore.BASE_URL + ApiCore.AUTH;
  public recoverPasswordEmail: string = '';
  public recoverCode: string = '';
  constructor(
    private http: HttpClient,
    private jwt: JwtService,
    private session: SessionService,
    private readonly router: Router
  ) {}

  /**
   * Login user using webservice to get a JSON Web Token and store it in Local Storage key Token
   * @param userName userName string value
   * @param password Password string value
   * @param keepMeSigned keepMeSigned boolean value
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  public login(
    userName: string,
    password: string,
    keepMeSigned: boolean
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept-encoding': 'gzip,deflate',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(
        this.baseUrl + ApiCore.SIGNIN,
        {
          userName,
          password,
          keepMeSigned,
        },
        httpOptions
      )
      .pipe(
        map((response: any) => {
          //console.log(response)
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * Sign a new user using webservice. User needs to login after, no JSON Web Token is stored
   * @param email Email string value
   * @param password Password string value
   * @param userName Name string value
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  public signin(
    email: string,
    password: string,
    userName: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept-encoding': 'gzip,deflate',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(
        this.baseUrl + ApiCore.SIGNIN,
        {
          email,
          password,
          userName,
        },
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * Sign a new user using webservice. User needs to login after, no JSON Web Token is stored
   * @param email Email string value
   * @param password Password string value
   * @param userName Name string value
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  public signup(
    email: string,
    password: string,
    userName: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(
        this.baseUrl + ApiCore.SIGNIUP,
        { email, password, userName },
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * Sign a new user using webservice. User needs to login after, no JSON Web Token is stored
   * @param email Email string value
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  public ExistEmail(email: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(this.baseUrl + ApiCore.EMAIL_AVAILABLE, { email }, httpOptions)
      .pipe(
        map((response: any) => {
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * Sign a new user using webservice. User needs to login after, no JSON Web Token is stored
   * @param userName Name string value
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  public ExistUserName(userName: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(
        this.baseUrl + ApiCore.USER_AVAILABLE,
        { userName },
        httpOptions
      )
      .pipe(
        map((response: any) => {
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * Removes the Local Storage key Token value
   */
  public logout(): Observable<any> {
    const decodedToken = this.jwt.getData(this.getToken(), 'id');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
      }),
    };
    return this.http
      .post<any>(this.baseUrl + ApiCore.LOGOUT, { decodedToken }, httpOptions)
      .pipe(
        map((response: any) => {
          this.session.removeItem('token');
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * Set the Local Sotrage key Token value with the JSON Web Token
   * @param token String with the valid JSON Web Token value
   */
  private setToken(token: string): void {
    //console.log(token)
    if (token === undefined) {
    } else {
      this.session.setItem('token', token);
    }
  }

  /**
   * Get the Email value if the JSON Web Token value is set in Local Storage key Token
   * @returns Email string value or empty string
   */
  public getEmail(): string {
    const decodedToken = this.jwt.getData(this.getToken());
    if (decodedToken == null) {
      return '';
    } else {
      return decodedToken.email;
    }
  }

  /**
   * Get the Name value if the JSON Web Token value is set in Local Storage key Token
   * @returns Name string value or empty string
   */
  public getName(): string {
    const decodedToken = this.jwt.getData(this.getToken());
    if (decodedToken == null) {
      return '';
    } else {
      return decodedToken.name;
    }
  }

  /**
   * Get the Name value if the JSON Web Token value is set in Local Storage key Token
   * @returns Name string value or empty string
   */
  private getToken(): string {
    const token = this.session.getItem('token');
    if (token == null) {
      return '';
    } else {
      return token;
    }
  }

  /**
   * Validates if the JSON Web Token from Local Storage key Token is set and it has not expired
   * @returns Boolean True or False
   */
  public isAuthenticated(): boolean {
    var expirated = !this.jwt.isExpired(this.getToken());
    //console.log(expirated)
    if (!expirated) this.logout();
    return expirated;
  }

  /**
   * Sends the JSON Web Token to the webserver to validate it in order to avoid corruption or falsification
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  verify() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
      }),
    };
    return this.http.get<any>(this.baseUrl + ApiCore.VERIFY, httpOptions).pipe(
      map((response) => {
        this.setToken(response.access_token);
        return response;
      }),
      catchError(this.errorHandlerVerify)
    );
  }

  /**
   * Verifies if the token time has expired
   * @param token The token to check
   * @returns Observable to which you will need to subscribe to get the webservice response or error
   */
  verifyTokenExpiration(token: string): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
      
    };
    return firstValueFrom(
      this.http.get<any>(this.baseUrl + ApiCore.VERIFY_TOKEN_EXPIRATION, {
        ...httpOptions,
        params: {
          token,
        },
      })
    );
    // return this.http.get<any>(this.baseUrl + ApiCore.VERIFY_TOKEN_EXPIRATION, {
    //     ...httpOptions,
    //     params: {
    //       token,
    //     },
    //   }).pipe(
    //     map((response: boolean) => {
    //       console.log({response});
    //       // this.session.setItem('token', data.token);
    //       return true;
    //     }),
    //     catchError(()=> of(false)))
    

  }

  /**
   * Catch the error and get the data from error property
   * @param error HttpErrorResponse object
   * @returns JSON object with the error property value
   */
  private errorHandler(error: HttpErrorResponse) {
    //console.log(error);
    let res = { Type: 'HTTPError', Code: error.status, Message: error.message };

    return throwError(() => {
      const errorR: any = new Error(`HTTPError:${error.status}`);
      errorR.timestamp = Date.now();
      errorR.data = res;
      errorR.message = error.message;
      return error;
    });
  }

  /**
   * Catch the error for Verify and get the data from error property
   * @param error HttpErrorResponse object
   * @returns JSON object with the error property value
   */
  private errorHandlerVerify(error: HttpErrorResponse) {
    this.session.removeItem('token');
    return throwError(() => new Error(error.error));
  }

  public recoverPassword(objectForm: any, endpoint: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(this.baseUrl + endpoint, objectForm, httpOptions)
      .pipe(
        map((response: any) => {
          //console.log(response)
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  public duplicatedPassword(
    objectForm: any,
    endpoint: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(ApiCore.BASE_URL + endpoint, objectForm, httpOptions)
      .pipe(
        map((response: any) => {
          this.setToken(response.access_token);
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

  destroySession() {
    const token = this.getToken();
    if (token !== '') {
      this.session.removeItem('token');
      this.router.navigate(['/app/auth/login'], {
        state: {
          anotherBrowser:
            'Su sesión fue cerrada. Se inició sesión en otro dispositivo o navegador',
        },
      });
    }
  }

  validDataBaseSessionToken(token: string | null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true',
      }),
    };
    return this.http
      .post<any>(
        this.baseUrl + ApiCore.AUTH + 'valid-database-session',
        { token },
        httpOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.errorHandler)
      );
  }
}
