import { Component, Input, OnInit, Output } from '@angular/core';
import { WalletSocketService } from './wallet.component.service';
import { ApiService } from 'src/app/core/http/api.service';
import { ApiCore } from 'src/app/config/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  

  @Input() public totalBalance!:number;
  @Input() showLoadSkeleton!: {
    wallet:   false,
    profile:  false,
  };
  public currencyCode: string = '';
  public currencySymbol: string = '';

  @Input() buyCredits: () => void=()=>{};
  constructor(
    private readonly walletService: WalletSocketService,
    private readonly apiService: ApiService,
    private readonly messageService: MessageService
  ) {
    this.walletService.outEven.subscribe((res:number) => {
      // console.log({resFromEvent:res});
      //TODO: Checar el suscribe para poder modificar cada veez que el wallet se actualice
      
      this.totalBalance = res;

    })
  }

  ngOnInit(): void {
    this.getOperatorCurrency();
  }
  //EMITE EVENT DESDE EL FRONT
  // private sayHello(data: { credits:number }, emit: boolean = true) {
  //   // console.log('Nuevo hola from ', emit?'true': 'false');
  //   if (emit) {
  //     //TODO: Cambiar el emit al momento de actualizar el wallet
  //     this.walletService.emitWalletUpdate(data)
  //   }
  // }

  // saludar(saludo:string){
  //   this.sayHello({ credits: new Date().getTime() });
  // }

  private getOperatorCurrency(): void {
    this.apiService.GetOperatorConfig().subscribe({
       next: (res) => {
         this.currencyCode = res.data.currencyCode;
         this.currencySymbol = res.data.currencySymbol;
       },
       error: (e) => {
         console.log(e);
       }
    });
  }
}
