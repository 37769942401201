import { NgModule } from '@angular/core';

import { BannerComponent } from './banner.component';

@NgModule({
  imports: [],
  exports: [BannerComponent],
  declarations: [BannerComponent],
  providers: [],
})
export class BannerModule { }
