<p-toast position="top-center" key="tc"></p-toast>

<form class="login-form fadeIn animated" [formGroup]="loginForm" (submit)="login()">

  <div class="login-body">
    <div class="login-image">
      <img [src]="operatorBanner" alt="atlantis" style="cursor: pointer;" [routerLink]="['/']">
    </div>
    <div class="login-panel p-fluid" >
        <div class="flex flex-column" style="max-width: 320px;">
            <div class="flex align-items-center" style="margin-bottom: 25px;">
                <img [src]="operatorLogo" style="width: 300px; height: 90px; cursor: pointer;" [routerLink]="['/']"/>
            </div>
            <div class="form-container">
              <span class="p-input-icon-left">
                  <i class="pi pi-user"></i>
                  <input style="border-color: transparent;" maxlength="16" formControlName="user" type="text" autocomplete="off" pInputText placeholder="{{'login.user' | translate}}" (focus)="clearToast()">
              </span>
              <div style="margin-top: 15px; margin-bottom: 15px;">
                <span class="p-input-icon-right">
                  <span class="p-input-icon-left">
                    <i ngClass="pi-key" class="pi" style=" margin-top: 10px;"></i>
                  </span>
                  <i [ngClass]="!showPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showPassword = !showPassword" style="cursor:pointer"></i>
                  <input (keyup.enter)="login()" style="padding: 0.429rem 2.2rem; border-color: transparent;" maxlength="16" formControlName="password" [type]="showPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'login.password' | translate}}">
                </span>
              </div>

                    <div style="display: flex;">
                        <p-checkbox value={{keep_session}} id="keep_session" formControlName="keepMeSigned"></p-checkbox>
                        <label style="margin-left: 10px;"> {{'login.keep_session' | translate}} </label>
                    </div>
                    <a routerLink="../recover-password" class="flex underline_link" style="margin-top: 10px;">{{'login.forgot_password' | translate}}</a>
                </div>
                <div>
                    <div style="display: flex; flex-direction: column;">
                        <!-- <button type="button" pButton label="{{'login.login_button' | translate}}" (click)="login()" [disabled]="loginForm.invalid" ></button> -->
                        <button type="button" pButton label="{{'login.login_button' | translate}}" (click)="login()" [disabled]="loginForm.invalid"></button>
                        <span style="margin-top: 15px; margin-left: 15px;">{{'login.dont_have_account' | translate}} <a routerLink="../signin" style="margin-left: 10px;" class="underline_link">{{'login.sign_up_here' | translate}} </a></span>
                    </div>
                </div>
                <div style="display: flex; margin-top: 35px; justify-content: center; flex-direction: column;">
                    <label style="margin-left: 10px;"> {{'login.allow_access' | translate}} </label>
                    <div style="display: flex; flex-direction: row; margin-top: 15px; justify-content: space-between;">
                        <a (click)="showTermsConditions = true" class="flex underline_link" style="margin-top: 10px;  cursor: pointer;">{{'login.terms_coditions' | translate}}</a>
                        <a (click)="showPrivacyPolicy = true" class="flex underline_link" style="margin-top: 10px;  cursor: pointer;">{{'login.privacity' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<app-terms-conditions [DisplaySideBar]="showTermsConditions" (onCloseSideBar)="showTermsConditions = $event"></app-terms-conditions>
<app-privacy-policy [DisplaySideBar]="showPrivacyPolicy" (onCloseSideBar)="showPrivacyPolicy = $event"></app-privacy-policy>
