import { environment } from "src/environments/environment";

export class ApiCore {
  public static BASE_URL = environment.baseUrl;
  // Authentication
  public static AUTH = 'auth/';
  public static LOGIN = 'login/';
  public static LOGOUT = 'logout';
  public static SIGNIN = 'signin/';
  public static SIGNIUP = 'signup/';
  public static VERIFY = 'verify/';
  public static VERIFY_TOKEN_EXPIRATION = 'verify-expiration/';
  public static USER = 'user';
  public static OPERATOR = 'operator';
  public static RECOVER_PASSWORD = 'password-reset-request';
  public static RESET_PASSWORD = 'password-reset';
  public static DUPLICATED_PASSWORD = 'validate-current-password';
  public static USER_UPLOAD_PERSONAL_ID = 'user/upload-personal-id';
  public static USER_UPLOAD_IMAGE_PROFILE = 'user/upload-profile-img';
  public static LOBBY_OPEN_GAMES = 'lobby-open/games';
  public static LOBBY_OPEN_SECTIONS = 'lobby-open/custom-sections/';
  public static LOBBY_OPEN_BANNERS = 'lobby-open/banners/';
  public static ENTER_GAMES = 'game/enterGame/';
  public static SESSION_GAMES = 'game-session/user-session';
  public static CATALOGS = 'catalogs/';
  public static EMAIL_AVAILABLE = 'email-availability/';
  public static USER_AVAILABLE = 'user-name-availability/';
  public static LOBBY_GAME_CAT = 'lobby-open/game-categories'
  public static GAME_FEATURED = 'game/featured-games'
  public static LOBBY_COUNT_CAT = 'lobby-open/category-count'
  public static LOBBY_GAME_VENDORS = 'lobby-open/game-vendors'
  public static PC_PLAYER = 'pc-players';

  // Generic API
  public static API = 'user';
  public static API_VERSION = '';
  // Special services API
  public static SPECIAL = '';
}
