import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SigninComponent } from './signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component'; import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// NG PRIME
import {  SharedModule } from "../../shared/modules/primeng.module";
import { ProfileComponent } from './profile/profile.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { CreditsInfoComponentComponent } from 'src/app/components/credits-info-component/credits-info-component.component';

export function HttpLoaderFactoryAuth(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/auth/', '.json');
}


@NgModule({
  declarations: [
    LoginComponent,
    SigninComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    ProfileComponent,
    RestorePasswordComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    CreditsInfoComponentComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es-MX',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactoryAuth,
        deps: [HttpClient]
      }
    })
  ],
  exports:[
    CreditsInfoComponentComponent
  ]
})
export class AuthModule { }
