import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToolsValidations } from 'src/app/core/tools/tools.validators';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})

export class LoginComponent implements OnInit,AfterViewInit {

  public loginForm: FormGroup = this.formBuilder.group({
    user: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    keepMeSigned :new FormControl(false),
  });

  public imgUrl: string = "https://insiderlatam.com/wp-content/uploads/2020/11/Casino2.jpg";
  public keep_session: boolean = true;
  public password: string = "";
  public showTermsConditions: boolean = false;
  public showPrivacyPolicy: boolean = false;
  public operatorLogo: string | null = "assets/images/no-photo-available.png";
  public operatorBanner: string | null = "";
  public showPassword = false;

  constructor(
    public app: AppComponent,
    private i18nService: I18nService,
    private translate: TranslateService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private session: SessionService,
    private apiService: ApiService,
  ) { 
    const state:any = this.router.getCurrentNavigation()?.extras.state;
    if(state){
      console.log(state)
      setTimeout(() => {
        this.messageService.add({ key: 'tc', severity: 'error', summary: state?.anotherBrowser, sticky: true, closable: true});
      }, 100);
    }

  }

  ngAfterViewInit(): void {
  }

  clearToast(){
    this.messageService.clear('tc');
  }

  async ngOnInit(): Promise<void> {
    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    })
    this.primengConfig.ripple = true;
    this.operatorLogo =  this.session.getItem("operatorLogo") ?? this.operatorLogo;
    await this.getCoorporateConfiguration();
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  processing: boolean = false;
  login(){
    if(this.processing) return;
    this.processing = true;
    const user = this.loginForm.get("user")?.value;
    const pass = this.loginForm.get("password")?.value;
    const keepMeSigned = this.loginForm.get("keepMeSigned")?.value;

    this.auth.login(user,pass,keepMeSigned[0] ?? false ).subscribe((res) => {
      this.messageService.add({key: 'tc',severity:'success', summary: 'Bienvenido'});
      this.router.navigate(['../app/home']);
      this.processing = false;
    },(error) => {
      this.processing = false;
      this.messageService.add({key: 'tc',severity:'error', summary: 'Credenciales incorrectas'});
      console.error(error);
    });
  }

  async getCoorporateConfiguration() {
    try {
      await this.apiService.getCoorporateCasinoConfig().subscribe(res => {
        this.session.setItem("operatorLogo", res.data.logoCasinoUrl);
        this.operatorLogo = res.data.logoCasinoUrl;
        this.operatorBanner = res.data.loginBannerUrl;
      });
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }
}
