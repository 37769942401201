import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { WalletSocketService } from 'src/app/components/wallet/wallet.component.service';
import { ApiCore } from 'src/app/config/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SessionService } from 'src/app/core/auth/session.service';
//import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';
//import { SocketServiceUpdate } from 'src/app/core/socket/socket-update.service';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ProfileMenuService } from 'src/app/shared/services/profile-menu.service';
import { BannerService } from 'src/app/components/banner/banner.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  showLoadSpin: boolean = false;
  isMobile: boolean = false;
  isLoggedIn: boolean = false;
  showMissedInformation: boolean = false;
  IsBuyCreditsVisible: boolean = false;
  showMissedInfoGame: boolean = false;

  logo: string | null = 'assets/images/no-photo-available.png';
  profilePicture: string | null = "assets/images/imageProfile.png"
  labelProfile: string = 'Mi Perfil'
  userStatus: any;
  idPlayer: any;
  updateWalletInfo: any;

  totalBalance: number = 0;

  showLoadSkeleton: any = {
    wallet:   false,
    profile:  false,
  };

  items: MenuItem[] = [];

  constructor(
    private i18nService: I18nService,
    private readonly auth: AuthService,
    private readonly api: ApiService,
    private readonly message: MessageService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly profileMenu: ProfileMenuService,
    private readonly userSession: SessionService,
    private readonly walletSocketService: WalletSocketService,
    private readonly bannerService: BannerService,
    private readonly session: SessionService,
    //private readonly socketUpdate: SocketServiceUpdate,
  ) { 
    this.verifyLoggedIn();
    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate() }
    });
    this.profileMenu.getData().subscribe((items: MenuItem[]) => {
      items.forEach(element => {
        switch (element.label) {
          case 'Mi Perfil':
            element.command = () => this.openProfile();
            break;
          case 'Cerrar Sesión':
            element.command = () => this.logout()
            break;
          case 'Lobby':
            element.command = () => this.openHome()
            break;
          default:
            break;
        }
      });
      this.items = items;
    });
/*
    this.socketUpdate.databaseUpdate().subscribe((data) => {
      try {
        this.updateWalletInfo = data;
      
        const userId = this.updateWalletInfo?.userId;
        const pcPlayerId = this.updateWalletInfo?.pcPlayerId;
      
        if (this.idPlayer === userId || this.idPlayer === pcPlayerId) {
          this.totalBalance = this.updateWalletInfo.balance;
        }
      
      } catch (error) {
        console.error('Error al obtener el valor actualizado:', error);
      }
      

    });
    */
  }

  ngOnInit(){
    this.getCoorporateConfiguration();
    this.walletSocketService.connectSocket()
    
  }
  ngOnDestroy(): void {
    this.walletSocketService.disconnectSocket();
    //this.socketUpdate.disconnectSocket(); // Desconectar la conexión al salir del componente
  }

  closeBuyCreditsDialog = (event: boolean) => this.IsBuyCreditsVisible = event;
  
  closeDialog = () => {
    this.showMissedInformation = false;
    this.showMissedInfoGame = false;
  }


  buyCredits = () => {
    if(this.userStatus == "Incompleto")
      this.showMissedInformation = true;
    else
      this.IsBuyCreditsVisible = true;
  }

  verifyLoggedIn = () => {
    try{
      this.isLoggedIn = this.auth.isAuthenticated();
    }catch(error){
      this.isLoggedIn = false;
      console.error(error);
    }

    if(this.isLoggedIn){
      this.verifyValidToken();
    }
  }

  getBalance(userId?: string, pcplayer?: boolean){
    if(pcplayer){
      this.api.list(ApiCore.PC_PLAYER+"/wallet-balance", {id : userId }).subscribe({
        next: res => {
          this.totalBalance =  res.data.balance;
        },
        error: err => {
          this.message.add({key: 'tc',severity: 'error', summary: 'Error al cargar el balance del usuario' }); 
          console.error(err);
        }
      });
    }else{
      this.api.list(ApiCore.USER+"/wallet-balance").subscribe({
        next: res => {
          this.totalBalance =  res.data.balance;
        },
        error: err => {
          this.message.add({key: 'tc',severity: 'error', summary: 'Error al cargar el balance del usuario' }); 
          console.error(err);
        }
      });
    }
    
  }

  getCoorporateConfiguration = () => {
    this.showLoadSkeleton.wallet = true;
    this.showLoadSpin = true;
    this.api.getCoorporateCasinoConfig().subscribe({
      next: res => {
        this.logo = res.data.logoCasinoUrl;
        this.showLoadSkeleton.wallet = false;
        this.showLoadSpin = false;

      },
      error: err => console.error(err)
    });
  }

  openProfile = () => this.router.navigate(['/app/home/profile']);

  openHome = () => this.router.navigate(['/'])

  useTranslate = () => this.translate.use(this.i18nService.getLanguage());

  verifyValidToken = () => {
    this.showLoadSkeleton.profile = true;
    this.api.list(ApiCore.USER+"/me").subscribe({
      next: res =>{
        //console.log('data me-->',res.data)
        this.profilePicture = res.data.profilePictureUrl ?? "../assets/images/imageProfile.png";
        this.userStatus = res.data.userStatus;
        this.idPlayer = res.data.id;
        this.showLoadSkeleton.profile = false;
        this.getBalance(res.data.id, res.data.isPCPlayer)
      },
      error: () =>{
        console.error('Su sesión expiró o no ha iniciado sesión');
        this.auth.destroySession();
      }
    });
  }

  logout = () => {
    //lo borramos de la tabla de delete session
    this.userSession.deleteUserGameSession().subscribe((res)=>{
      console.log('destroying session...', res);
      this.auth.logout().subscribe({
        next: () => {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/app/auth/login']));
          this.isLoggedIn = false;            
        },
        error: err => console.error(err)
      });
    })
  }
  redirectHome(){
    // Si está en open-game tenemos que borrar la sessión del juego
    this.bannerService.changeNameOfBanner(this.session.getItem('casinoName') as string)
    const urlWithoutQuery = this.router.url.split('?')[0];
    console.log(urlWithoutQuery);
    if(urlWithoutQuery === '/app/home/open-game'){
      this.deleteCurrentGameSession();
    }
    //siempre redirigir al home
    this.router.navigate(['/app/home'])
  }

  private deleteCurrentGameSession(){
    this.userSession.deleteUserGameSession().subscribe((res)=>{
      console.log('destroying session...');
      console.log(res);
    })
  }
}
