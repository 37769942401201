<p-toast position="top-center" key="tc"></p-toast>

<!-- responsive -->
<div *ngIf="isMobile && !isLandscape" style="background-color: #292b39; position: fixed; width: 100%; height: 100%; top: 0; right: 0; z-index: 10;"></div>
<p-dialog *ngIf="isMobile && !isLandscape" [visible]="true" [closable]="false" [style]="{width: '70vw'}">
    <ng-template pTemplate="header">
        <p class="text-xl font-bold" style="width:100%; text-align: center;">Gira tu dispositivo</p>
    </ng-template>
    <div style="text-align: center; margin-bottom: 20px;">
        <i class="pi pi-undo" style="font-size: 10rem"></i>
    </div>
    <p>¡Para disfrutar del Casino, gira tu dispositivo a <strong>posición horizontal</strong>!</p>
</p-dialog>
<!-- responsive -->

<!-- responsive -->
<div *ngIf="isMobile && !isLandscape" style="background-color: #292b39; position: fixed; width: 100%; height: 100%; top: 0; right: 0; z-index: 10;"></div>
<p-dialog *ngIf="isMobile && !isLandscape" [visible]="true" [closable]="false" [style]="{width: '70vw'}">
    <ng-template pTemplate="header">
        <p class="text-xl font-bold" style="width:100%; text-align: center;">Gira tu dispositivo</p>
    </ng-template>
    <div style="text-align: center; margin-bottom: 20px;">
        <i class="pi pi-undo" style="font-size: 10rem"></i>
    </div>
    <p style="text-align: center;">¡Para disfrutar del Casino, gira tu dispositivo a <strong>posición horizontal</strong>!</p>
</p-dialog>
<!-- responsive -->


<form class="login-form fadeIn animated scroll-mobile" [formGroup]="profileForm">
    <div style="display: flex; flex-direction: column;">
        <div class="container-mobile" style="display:flex; width: 100%; flex-direction: row; flex-wrap: wrap; justify-content: center;">
            <!-- <div class="col-profile card-profile container-mobile marginGlobal" style=" display: flex; justify-content: flex-end; margin-left: 15px; width: 20%;"> -->
                <label class="hidden show text-white font-bold text-3xl mb-3 card-profile"> {{'profile.title' | translate}}</label>
                <div class="cardAdjust" style="background-color: #4c4c58; box-shadow: 0px 15px 15px rgb(26, 26, 26); width: 300px; margin-top: 25px; margin-right: 20px;max-height: 500px;  border-radius: 5px;">
                    <div class="containerProfile">
                        <div class="containerProfile" style="width: 150px;" (mouseleave)="showEditImage = false">
                            <img alt="Avatar" (mouseenter)="showEditImage = true" [src]=profilePicture class="profileAvatar" (click)="openImage();">
                            <img alt="Avatar" (mouseenter)="showEditImage = true" *ngIf="showEditImage" [src]=profilePictureEdit class='profileAvatarEdit' (click)="openImage();">
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <p-menu [style]="{'width':'100%'}" [model]="items"></p-menu>
                    </div>
                    <input class="hidden" type="file" id="upload-file" name="upload-file" accept="image/png, image/gif, image/jpeg" ngf-max-size="2MB" (cancel)="closeDialog()" (change)="addAttachment($event)">
                    <input class="hidden" type="file" id="upload-file-2" name="upload-file-2" accept="image/png, image/gif, image/jpeg" ngf-max-size="2MB" (cancel)="closeDialog()" (change)="addDocument($event)">
                </div>
            <!-- </div> -->
            <div class="global-col col-credits" style="width:65%;">
                <div class="card-credits">
                    <!-- <label class="header_title NoShowMobile">{{'profile.my_credits' | translate}}</label> -->
                    <!-- <div class="credits-zone" style="background-color: #4c4c58; border-radius: 5px; margin-top: 10px; padding-top: 15px; padding-bottom: 25px; flex-direction: row; display: flex; flex-wrap: wrap;">
                        <div class="data-profile-zone" style="margin-top: 5px; width: 30%; padding-left: 20px; ">
                            <div class="name-zone" style="height: 50%; margin-left: 5px;">
                                <i class="pi pi-user" style="margin-right: 5px;"></i> {{userProfile.userName ?? ""}}
                            </div>
                            <div style="height: 50%;  margin-left: 5px; margin-top: 5px;">
                                <i class="pi pi-envelope" style="margin-right: 5px;"></i> {{userProfile.email ?? ""}}
                            </div>
                        </div>
                        <div class="mt-3 profile-btns" style=" display: flex; justify-content: space-around; width: 60%; align-items: center;">
                            <button class="btn-credits-2" type="button" style="width: 150px; height: 25px;" pButton label="{{'profile.buy_credits' | translate}}" (click)="BuyCredits()"></button>
                            <button class="btn-credits-2" type="button" style="width: 150px; height: 25px;" pButton label="{{'profile.retry_credits' | translate}}" (click)="BuyCredits()"></button>
                        </div>

                    </div> -->
                    <!-- <div class="balance-section" style="background-color: #4c4c58; padding-bottom: 20px; padding-top: 15px;   border-radius: 5px; margin-top: 25px; flex-direction: row; display: flex; justify-content: space-around;"> -->
                        <!-- <div class="balance-section-2" style="display: flex; flex-direction:column; width: 30%;">
                            <div style="display: flex; justify-content:center;">
                                <label style="font-size: 15px;">{{'profile.balance' | translate}}</label>
                            </div>
                            <div class="balance-section-3" style="background-color: #6c6c76; width:100%; height: 35px; border-radius: 5px; justify-content: center; display: flex; flex-direction: row; margin-top: 5px;">
                                <div style="justify-content: center; display: flex; width: 100%; align-items: center; padding-left: 20px;">
                                    <label style="font-size: 14px;">{{balance | creditsFormatter:{currencyCode, currencySymbol, credit: true} }} </label>
                                </div>
                                <div style="justify-content: center; display: flex; width: 15px; align-self: flex-end; margin-bottom: 5px; margin-right: 5px;">
                                    <i class="pi pi-info-circle" style="font-size: 10px; color: #0F8BFD;" pTooltip="Total de créditos comprados o ganados que puedes apostar y retirar."></i>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="balance-section-2" style="display: flex; flex-direction:column; width: 30%;">
                            <div style="display: flex; justify-content:center;">
                                <label style="font-size: 15px;">{{'profile.bonus' | translate}}</label>
                            </div>
                            <div class="balance-section-3" style="background-color: #6c6c76; width:100%; height: 35px; border-radius: 5px; justify-content: center; display: flex; flex-direction: row; margin-top: 5px;">
                                <div style="justify-content: center; display: flex; width: 100%; align-items: center; padding-left: 20px;">
                                    <label style="font-size: 14px;">{{bonus}} {{'profile.credits' | translate}}</label>
                                </div>
                                <div style="justify-content: center; display: flex; width: 15px; align-self: flex-end; margin-bottom: 5px; margin-right: 5px;">
                                    <i class="pi pi-info-circle" style="font-size: 10px; color: #0F8BFD;" pTooltip="Total de créditos obtenidos por bonos que no puedes retirar, pero puedes apostar para ganar y aumentar tu balance normal."></i>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="balance-section-2" style="display: flex; flex-direction:column; width: 30%;">
                            <div style="display: flex; justify-content:center;">
                                <label style="font-size: 15px;">{{'profile.total_balance' | translate}}</label>
                            </div>
                            <div class="balance-section-3" style="background-color: #6c6c76; width:100%; height: 35px; border-radius: 5px; justify-content: center; display: flex; flex-direction: row; margin-top: 5px;">
                                <div style="justify-content: center; display: flex; width: 100%; align-items: center; padding-left: 20px;">
                                    <label style="font-size: 14px;">{{totalBalance}} {{'profile.credits' | translate}}</label>
                                </div>
                                <div style="justify-content: center; display: flex; width: 15px; align-self: flex-end; margin-bottom: 5px; margin-right: 5px;">
                                    <i class="pi pi-info-circle" style="font-size: 10px; color: #0F8BFD;" pTooltip="Total de créditos disponibles que puedes apostar."></i>
                                </div>
                            </div>
                        </div> -->
                    <!-- </div> -->
                    <div style="background-color: #4c4c58;  box-shadow: 0px 15px 15px rgb(26, 26, 26); border-radius: 5px; margin-top: 25px; flex-direction: row; display: flex; justify-content: space-around;">
                        <div style="width: 100%; padding: 7px 5px;">
                            <div style="width: 100%;">
                                <label class="header_title titleResize">{{'profile.personal_information' | translate}}</label>
                            </div>
                            <div class="personal-section-1" style="width: 100%; display: flex; flex-direction: row; justify-content: space-around; ">
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 50%; padding: 10px; margin-left: 8px;">
                                    <label class="labelDisableClass">{{'profile.user_name' | translate}}</label>
                                    <input formControlName="userName" readonly class="readOnlyInput" pInputText />
                                </div>
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 50%; padding: 10px; margin-left: 8px; margin-right: 8px;">
                                    <label class="labelDisableClass">{{'profile.email' | translate}}</label>
                                    <input formControlName="mail" readonly class="readOnlyInput" type="email" email="true" pInputText />
                                </div>
                            </div>

                            <div class="personal-section-1" style="width: 100%; display: flex; flex-direction: row; justify-content: space-around; ">
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 50%; padding: 10px; margin-left: 8px;">
                                    <label class="labelClass">{{'profile.name' | translate}}</label>
                                    <!-- <input [ngClass]="{'readOnlyInput': userVrified == 'C'}" [disabled]="userVrified == 'C'"  (input)="validateUserFullName()" maxlength="30" minlength="3" formControlName="firstName" style="margin-top: 0px;"  type="text" class="p-inputtext" placeholder="{{'profile.name_placeholder' | translate}}" pInputText /> -->
                                    <input (input)="validateUserFullName()" maxlength="30" minlength="3" formControlName="firstName" style="margin-top: 0px;" type="text" class="p-inputtext" placeholder="{{'profile.name_placeholder' | translate}}" pInputText />
                                    <small style="color: #FC6161 ;" *ngIf="messageUserFullNameError !== ''" class="p-invalid">{{messageUserFullNameError}}</small>
                                </div>
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 50%; padding: 10px; margin-left: 8px; margin-right: 8px;">
                                    <label class="labelClass">{{'profile.last_name' | translate}}</label>
                                    <!-- <input [ngClass]="{'readOnlyInput': userVrified == 'C'}" [disabled]="userVrified == 'C'" (input)="validateUserLastName()" [maxlength]="30" formControlName="lastName" style="margin-top: 0px;"  type="text" class="p-inputtext" placeholder="{{'profile.last_name_placeholder' | translate}}" pInputText /> -->

                                    <input (input)="validateUserLastName()" [maxlength]="30" formControlName="lastName" style="margin-top: 0px;" type="text" class="p-inputtext" placeholder="{{'profile.last_name_placeholder' | translate}}" pInputText />
                                    <small style="color: #FC6161 ;" *ngIf="messageUserFullLastNameError !== ''" class="p-invalid">{{messageUserFullLastNameError}}</small>
                                </div>
                            </div>

                            <div class="personal-section-1" style="width: 100%; display: flex; flex-direction: row; justify-content: space-around; ">
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 50%; padding: 10px; margin-left: 8px;">
                                    <label class="labelClass">{{'profile.birthday_date' | translate}}</label>
                                    <!-- <p-calendar [ngClass]="{'readOnlyInput': userVrified == 'C'}" [disabled]="userVrified == 'C'" placeholder="{{'profile.birthday_date_placeholder' | translate}}" (ngModelChange)="changeDate()" formControlName="birthday"  _ngcontent-luq-c123 class="p-element p-inputwrapper ng-tns-c40-31 ng-star-inserted" [showIcon]="true" inputId="icon"></p-calendar> -->
                                    <p-calendar placeholder="{{'profile.birthday_date_placeholder' | translate}}" (ngModelChange)="changeDate()" formControlName="birthday" _ngcontent-luq-c123 class="p-element p-inputwrapper ng-tns-c40-31 ng-star-inserted" [showIcon]="true" inputId="icon"></p-calendar>
                                    <small style="color: #FC6161 ;" *ngIf="!isValidBirthday && profileForm.get('birthday')?.value != 'Invalid date'" class="p-invalid">Debes tener por lo menos 18 años.</small>
                                </div>
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 50%; padding: 10px; margin-left: 8px; margin-right: 8px;">
                                    <label class="labelClass">{{'profile.genered' | translate}}</label>
                                    <p-dropdown class="" formControlName="genere" [options]="generes_catalog" optionLabel="name"></p-dropdown>
                                </div>
                            </div>

                            <div class="personal-section-1" style="width: 100%; display: flex; flex-direction: row; justify-content: flex-start; ">
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 60%; padding: 10px; margin-left: 8px;">
                                    <label class="labelClass">{{'profile.country_code' | translate}}</label>
                                    <p-dropdown formControlName="country" (onChange)="changeSelect()" [options]="countries_code_catalog" [(ngModel)]="selectedCountry" optionLabel="flagCode" [filter]="true" filterBy="country" [showClear]="true" placeholder="Seleccione un país">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="country-item country-item-value" style="display: flex;" *ngIf="selectedCountry">
                                                <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.flagCode.toLowerCase()" />
                                                <div style="margin-left: 10px;">{{selectedCountry.country}} {{selectedCountry.ladaCode}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-country pTemplate="item">
                                            <div class="country-item" style="display: flex;">
                                                <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.flagCode.toLowerCase()" />
                                                <div style="margin-left: 10px;">{{country.country}}{{country.ladaCode}}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div class="personal-inputs" style="flex-direction: column; display: flex; width: 60%; padding: 10px; margin-left: 8px; margin-right: 8px;">
                                    <label class="labelClass">{{'profile.telephone' | translate}}</label>
                                    <input (input)="validatePhoneNumber()" min="99999" max="999999999999" formControlName="phoneNumber" style="margin-top: 0px;" type="number" class="p-inputtext" placeholder="{{'profile.telephone_placeholder' | translate}}" pInputText />
                                    <small style="color: #FC6161 ;" *ngIf="messagePhoneNumberError !== ''" class="p-invalid">{{messagePhoneNumberError}}</small>
                                </div>
                            </div>
                            <div style="width: 100%; display: flex; flex-direction: row; justify-content:flex-end;">
                                <button type="button" style="width: 150px; height: 25px; margin-right: 10px; margin-top:10px; margin-bottom:10px" (click)="saveData()" pButton label="Guardar" [disabled]="profileForm.invalid || !isValidBirthday || !profileForm.dirty "></button>
                            </div>
                            <!--
                            <div *ngIf="!hasCompleteInformation">
                                <div style="width: 100%; display: flex; flex-direction: row; justify-content:center; margin-top: 15px;">
                                    <div class="row">
                                         "Para utilizar la función del Cajero, será necesario completar* tu Información Personal." 
                                        <label style="font-size: 15px;">{{'profile.bottom_text1' | translate}} </label>
                                        <label style="font-size: 15px; font-weight: bold;">{{'profile.bottom_text2' | translate}}</label>
                                        <label style="font-size: 15px;">{{'profile.bottom_text3' | translate}}</label>
                                        <label style="font-size: 15px; font-weight: bold;">{{'profile.bottom_text4' | translate}}</label>
                                        <label style="font-size: 15px;">.</label>

                                    </div>
                                </div>
                                <div *ngIf="peerVerification" style="width: 100%; display: flex; flex-direction: row; justify-content:center; margin-top: 15px;">
                                    <div class="row"><label style="font-size: 15px;">{{'profile.bottom_text_ln2' | translate}}</label><br/></div>
                                </div>
                            </div>
                        -->

                        </div>
                    </div>
                </div>
                <div class="identity" *ngIf="false" style="background-color: #4c4c58; border-radius: 5px; margin-top: 25px; flex-direction: row; display: flex; justify-content: space-around;">
                    <div style="width: 100%; padding: 15px 10px;">
                        <div style="width: 100%;" class="flex flex-wrap md:justify-content-between justify-content-center card-container center-verify">

                            <label class="header_title titlesProfile">{{'profile.personal_verification.title' | translate}}</label>
                            <i *ngIf="userVrified == 'P'" class="pi pi-info-circle mr-2 icon-position" style="font-size: 2.5rem;color: #0F8BFD;cursor: pointer;" (click)="showPendingVerificationNotification()"></i>
                            <i *ngIf="userVrified == 'C'" class="pi pi pi-check mr-2 icon-position" style="font-size: 2.5rem;color: chartreuse;cursor: pointer;" (click)="showPendingVerificationNotification()"></i>
                        </div>
                        <div style="width: 100%;" class="ml-3 mt-3 container-paragraph" *ngIf="!userProfile?.idDocumentSubmitDate && !userProfile?.idDocumentUrl">
                            <label class="paragraph" style="font-size: 15px;">{{'profile.personal_verification.disclaimer_ln1' | translate}}</label>
                        </div>
                        <div *ngIf="peerVerification_credits && !userProfile?.idDocumentSubmitDate && !userProfile?.idDocumentUrl" style="width: 100%;" class="ml-3 mt-3 container-paragraph">
                            <label style="font-size: 15px;">{{peerVerification_line2}}</label>
                        </div>

                        <div *ngIf="userVrified == 'N'" style="width: 100%" class="flex flex-wrap justify-content-center card-container mt-2 mb-2">
                            <div class="flex flex-wrap md:justify-content-between justify-content-center card-container button-container">
                                <button pbutton="" style="border: 3px solid; width: 20em;" pRipple type="button" label="Primary" (click)="onDocumentUploadClick()" class="p-element p-ripple p-button-outlined mr-2 mb-2 p-button p-component button-Width">
                          <span class="p-button-label" style="color: white;">{{'profile.personal_verification.search_doc' | translate}}

                            <i *ngIf="fileReadedDocument == null" class="pi pi-image ml-2" style="color: white;" ></i>
                            <i *ngIf="fileReadedDocument == null" class="pi pi-upload"  style="font-size: 10px; color: white;"></i>
                            <i *ngIf="fileReadedDocument != null" class="pi pi-check ml-2" style="color: green;" ></i>
                          </span>
                          <span class="p-ink" style="  top: -20.5px; left: -4.5px;"></span>
                        </button>
                            </div>


                            <div style="width: 100%;" class=" flex flex-wrap justify-content-center card-container  mt-3 mb-3 ">
                                <p-button style="width: 90%" (click)="UploadDocument()" [disabled]="fileReadedDocument == null">
                                    <div style="width: 100%;" class="pt-1 pb-1">
                                        <span class="ml-2 font-bold" style="color: white;">{{'profile.personal_verification.send_doc' | translate}}
                            <i class="pi pi-send ml-2" style="color: white;"></i>
                          </span>
                                    </div>

                                </p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<form class="login-form fadeIn animated" [formGroup]="passwordChangeForm">
    <p-dialog [style]="{width: '600px'}" [modal]="true" [draggable]="false" [(visible)]="showRestore" (onHide)="closeDialog()">
        <p-header>
            <div style="justify-content: center;flex-direction: column;display: flex;">
                <label class="header_title">{{'dialog_restore.title' | translate}}</label>
            </div>
        </p-header>
        <label class="content_text" style="margin-left: 10px; display: block;">Al restablecer tu contraseña, tendrás que volver a iniciar sesión.</label>
        <div style="justify-content: center;flex-direction: column;display: flex;">
            <div style="flex-direction: column; display: flex; padding: 10px">
                <label class="labelClass">{{'dialog_restore.current_password' | translate}}</label>
                <span class="p-input-icon-right">
                  <span class="p-input-icon-left">
                    <i ngClass="pi-key" class="pi" style=" margin-top: -12px;"></i>
                  </span>
                <i [ngClass]="!showCurrentPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showCurrentPassword = !showCurrentPassword" style="cursor:pointer"></i>
                <input style="padding: 0.429rem 2.2rem;" (input)="validateCurrentPassword($event)" maxlength="16" formControlName="currentPassword" [type]="showCurrentPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'dialog_restore.current_password_placeholder' | translate}}">
                </span>
                <small style="color: #FC6161 ;" *ngIf="!isValidCurrentPassword &&  passwordChangeForm.get('currentPassword')?.value != ''" class="p-invalid">Contraseña incorrecta</small>
            </div>
            <div style="flex-direction: column; display: flex; padding: 10px">
                <label class="labelClass">{{'dialog_restore.new_password' | translate}}</label>
                <span class="p-input-icon-right">
              <span class="p-input-icon-left">
                <i ngClass="pi-key" class="pi" style=" margin-top: -12px;"></i>
              </span>
                <i [ngClass]="!showNewPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showNewPassword = !showNewPassword" style="cursor:pointer"></i>
                <input (input)="validatePassword()" style="padding: 0.429rem 2.2rem;" maxlength="16" formControlName="newPassword" [type]="showNewPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'dialog_restore.new_password_placeholder' | translate}}">
                </span>
                <small style="color: #FC6161 ;" class="p-invalid">{{messagePasswordError}}</small>
            </div>
            <div style="flex-direction: column; display: flex; padding: 0px 10px">
                <label style="font-size: 12px; color: gainsboro;"> {{'errors.min8' | translate}}</label>
                <label style="font-size: 12px; color: gainsboro;"> {{'errors.min1' | translate}}</label>
                <label style="font-size: 12px; color: gainsboro;"> {{'errors.minUpper' | translate}}</label>
                <label style="font-size: 12px; color: gainsboro;"> {{'errors.minLow' | translate}}</label>
            </div>
            <div style="flex-direction: column; display: flex; padding: 10px">
                <label class="labelClass">{{'dialog_restore.confirm_password' | translate}}</label>
                <span class="p-input-icon-right">
              <span class="p-input-icon-left">
                <i ngClass="pi-key" class="pi" style=" margin-top: -12px;"></i>
              </span>
                <i [ngClass]="!showConfirmPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showConfirmPassword = !showConfirmPassword" style="cursor:pointer"></i>
                <input (input)="validateSamePassword()" style="padding: 0.429rem 2.2rem;" maxlength="16" formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'dialog_restore.confirm_password_placeholder' | translate}}">
                </span>
                <small style="color: #FC6161 ;" *ngIf="!isSamePassword &&  passwordChangeForm.get('confirmPassword')?.value != ''" class="p-invalid">Tu contraseña no es igual</small>
            </div>
        </div>
        <p-footer>
            <button icon="pi pi-times" type="button" pButton label="Cancelar" (click)="closeDialog()"></button>
            <button icon="pi pi-check" type="button" pButton label="Cambiar y finalizar" [disabled]="!isSamePassword || passwordChangeForm.invalid || messagePasswordError != ''" (click)="updatePassword()"></button>
        </p-footer>
    </p-dialog>
</form>
<form class="login-form fadeIn animated" [formGroup]="userChangeForm">
    <p-dialog [style]="{width: '600px'}" [modal]="true" [draggable]="false" [(visible)]="showChangeUserName" (onHide)="closeDialog()">
        <p-header>
            <div style="justify-content: center;flex-direction: column;display: flex;">
                <label class="header_title">{{'change_user_dialog.title' | translate}}</label>
            </div>
        </p-header>
        <div style="justify-content: center;flex-direction: column;display: flex;">
            <label class="content_text">{{'change_user_dialog.subTitle' | translate}}</label>
            <div style="flex-direction: column; display: flex; padding: 10px">
                <label class="labelClass">{{'change_user_dialog.new_name_user' | translate}}</label>
                <input (blur)="validateUserName()" (input)="validateUserName()" style="margin-top: 5px;" formControlName="changeUserName" type="text" class="p-inputtext" placeholder="{{'change_user_dialog.new_name_user_placeholder' | translate}}" pInputText />
                <small style="color: #FC6161 ;" *ngIf="messageUserNameError != ''" class="p-invalid">{{messageUserNameError}}</small>
            </div>
            <div style="flex-direction: column; display: flex; padding: 10px">
                <label class="labelClass">{{'change_user_dialog.confirm_new_name_user' | translate}}</label>
                <input (input)="validateSameUserName()" (blur)="validateSameUserName()" style="margin-top: 5px;" formControlName="confirmChangeUserName" type="text" class="p-inputtext" placeholder="{{'change_user_dialog.confirm_new_name_user_placeholder' | translate}}"
                    pInputText [disabled]=true />
                <small style="color: #FC6161 ;" *ngIf="messageSameUserNameError != ''" class="p-invalid">{{messageSameUserNameError}}</small>
            </div>
        </div>
        <p-footer>
            <button icon="pi pi-times" type="button" pButton label="Cancelar" (click)="closeDialog()"></button>
            <button icon="pi pi-check" type="button" pButton label="Cambiar y finalizar" (click)="updateUserName()" [disabled]="messageUserNameError != '' || messageSameUserNameError != '' || !isValidUser || userChangeForm.get('confirmChangeUserName')?.value == ''"></button>
        </p-footer>
    </p-dialog>
</form>

<p-dialog appendTo="body" [style]="{width: '500px', 'z-index': 1100}" [modal]="true" [draggable]="false" [(visible)]="showMissedInformation" (onHide)="closeDialog()">
    <p-header>
        <div style="justify-content: center;flex-direction: column;display: flex; align-items: center;">
            <label class="header_title">{{'profile.dialogMissidFiles.header' | translate}}</label>
        </div>
    </p-header>
    <div style="justify-content: center; display: flex; flex-wrap: wrap;">
        <span>Para &nbsp;</span><span style="font-weight:bold;">comprar o retirar&nbsp;</span><span>créditos, será necesario que completes la&nbsp;</span><span style="font-weight:bold;">Información Personal&nbsp;</span><span>de tu pérfil.</span>
    </div>
    <p-footer>
        <div style="display: flex; justify-content:center;">
            <button type="button" style="width: 40%;" pButton label="{{'profile.dialogMissidFiles.button' | translate}}" (click)="closeDialog()"></button>
        </div>

    </p-footer>
</p-dialog>

<app-credits-info-component [showCreditsDialog]="IsBuyCreditsVisible" (onCloseCreditsDialog)="closeCreditsDialog($event)"></app-credits-info-component>