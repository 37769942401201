import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';


@Injectable({
  providedIn: 'root'
})
export class SetTitleService {
    CASINO_NAME:string = '';
  constructor(private readonly api: ApiService, private readonly sesion: SessionService) {
    console.log('Init title service');
    this.api.getCoorporateCasinoConfig().subscribe({
        next: (res) => {
            this.sesion.setItem('casinoName', res.data.casinoName);
            this.CASINO_NAME = res.data.casinoName;
        },
        error: (err) => {
            console.error(err);
        }
    })
  }

  get titleProject(): string{
    return this.CASINO_NAME;
  }
}




