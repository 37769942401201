import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SessionService } from 'src/app/core/auth/session.service';
import { ApiService } from 'src/app/core/http/api.service';
import { ToolsValidations } from 'src/app/core/tools/tools.validators';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ApiCore } from '../../../config/api';
import { IDuplicatedPassword, IError, IResetPassword } from '../../../models/Auth/AuthInterfaces';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent implements OnInit, OnDestroy {

  public restoreForm = this.formBuilder.group({
    password:['',[Validators.required, Validators.pattern(ToolsValidations.patternPassword)]],
    confirmedPassword:['',[Validators.required, Validators.pattern(ToolsValidations.patternPassword)]]
  })
  public errors: string = '';
  public errorDuplicated: string = '';
  public hasErrors: boolean = false;
  public isSamePassword: boolean = false;
  public isDuplicated: boolean = false;
  operatorLogo: string | null ="assets/images/no-photo-available.png";
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  public get getPassword(){
    return this.restoreForm.get('password');
  }

  public get getConfirmedPassword(){
    return this.restoreForm.get('confirmedPassword');
  }

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private session: SessionService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    })
    this.getCoorporateConfiguration();
  }

  async getCoorporateConfiguration() {
    try {
      await this.apiService.getCoorporateCasinoConfig().subscribe(res => {
        this.session.setItem("operatorLogo", res.data.logoCasinoUrl);
        this.operatorLogo = res.data.logoCasinoUrl;
      });
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  async validatePassword(){

    const password : string = this.restoreForm.get("password")?.value!;
    this.hasErrors = true;
    this.errors = "";
    await this.checkDuplicatedPassword();
    if(password.length < 8)
      this.errors= "Utiliza al menos 8 caracteres";
    else if(password.length > 16)
      this.restoreForm.get("password")?.setValue(password.substring(0,16));
    else if(!RegExp(ToolsValidations.patternLowerCase).test(password))
      this.errors= "Utiliza al menos una letra minúscula.";
    else if(!RegExp(ToolsValidations.patternUpperCase).test(password))
      this.errors= "Utiliza al menos una letra mayúscula.";
    else if(!RegExp(ToolsValidations.patternNumber).test(password))
      this.errors= "Utiliza al menos una número.";
    else if(!RegExp(ToolsValidations.patternUser).test(password))
      this.errors= "Tu contraseña no puede tener espacios ni caracteres especiales.";
    else
      this.hasErrors = false;
    if(this.getConfirmedPassword?.value != "")
      this.validateSamePassword();
  }

  validateSamePassword()
  {
    const password = this.getPassword?.value;
    const confPassword = this.getConfirmedPassword?.value;

    if(password !== confPassword){
      this.getConfirmedPassword?.setErrors({'incorrect': true});
      this.isSamePassword = false;
      return;
    }
    this.getConfirmedPassword?.setErrors(null);
    this.isSamePassword = true;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  sendNewPassword(){
    const resetPassword = {
      "restoreCode":this.authService.recoverCode,
      "password":this.getPassword?.value
    }

    if(!resetPassword.restoreCode || !resetPassword.password){
      this.messageService.add({key: 'te',severity:'error', summary: `Código vencido.`});
      return
    }

    this.authService.recoverPassword(resetPassword,ApiCore.RESET_PASSWORD).subscribe(async (resp: IResetPassword)=>{
      if(resp.statusCode === 403){
        this.messageService.add({key: 'te',severity:'error', summary: `Codigo vencido.`});
        return;
      }
      this.messageService.add({key: 'te',severity:'success', summary: 'Cambio de contraseña exitoso.'});
      await this.delay(1000);
      this.session.removeItem('recoveryEmail')
      this.router.navigate(['../app/auth/login'])
    },(error: HttpErrorResponse) => {
      const RecoverError: IError = error.error
      console.error(RecoverError);
      if(RecoverError.statusCode === 400){
        this.messageService.add({key: 'te',severity:'error', summary: 'Contraseña no válida.'})
        return
      }
      this.messageService.add({key: 'te',severity:'error', summary: 'Código vencido'})
    })

  }

  async checkDuplicatedPassword(){
    this.errorDuplicated = '';
    const duplicatedPassword = {
      "email": this.session.getItem('recoveryEmail'),
      "password": this.getPassword?.value
    }

    if(!duplicatedPassword.password){
      return
    }
    await this.authService.duplicatedPassword(duplicatedPassword,`${ApiCore.USER}/${ApiCore.DUPLICATED_PASSWORD}`).subscribe(async(resp: IDuplicatedPassword)=>{
      if(resp.statusCode!=200)
      {
        this.messageService.add({key: 'te',severity:'error', summary: 'Error request'})
      }

      this.isDuplicated = resp.data.passwordMatches
      if(this.isDuplicated)
      {
        this.errorDuplicated = "Tu nueva contraseña debe ser diferente a la actual"
        return
      }

    },( error: HttpErrorResponse)=>{
      const RecoverError: IError = error.error
      console.error(RecoverError);
      if(RecoverError.statusCode === 400){
        this.messageService.add({key: 'te',severity:'error', summary: JSON.stringify(RecoverError.message)})
        return
      }
      this.messageService.add({key: 'te',severity:'error', summary: JSON.stringify(RecoverError.message)})
    })
  }

  ngOnDestroy(): void {
    //this.session.removeItem('recoveryEmail')
  }

}
