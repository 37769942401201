import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiCore } from 'src/app/config/api';
import { ApiService } from 'src/app/core/http/api.service';
import { ITermsAndConditions } from 'src/app/models/Auth/AuthInterfaces';
import { I18nService } from 'src/app/shared/services/i18n.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  public title = ''
  public TerminosCondiciones: string = ''
  @Input() DisplaySideBar: boolean = false;
  @Output() onCloseSideBar = new EventEmitter<boolean>();
  constructor(private api: ApiService,private i18nService: I18nService,private translate: TranslateService) { }

  ngOnInit(): void {
    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    })
    this.api.get(`${ApiCore.CATALOGS}privacy-policy-catalog`,'')
      .subscribe((resp: ITermsAndConditions)=>{

        if(resp.statusCode !== 200){
          return;
        }
        if(!resp.data[0])
          return;

        const data = resp.data[0];
        this.title = data.name;
        this.TerminosCondiciones = data.description;
      },(error) =>console.error(error));

  }

  public useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }


  public CloseSideBar(){
    this.onCloseSideBar.emit(false)
  }

}
