import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  nameOfBanner = new BehaviorSubject('');
  constructor() {}

  changeNameOfBanner(name: string) {
    this.nameOfBanner.next(name);
  }
}
