<p-dialog appendTo="body" class="credits-container" [style]="{width: '400px'}" [modal]="true" [draggable]="false" [(visible)]="showCreditsDialog" (onHide)="closeBuyCreditsDialog()">
    <p-header>
        <div style="justify-content: center;flex-direction: column;display: flex; align-items: center;">
            <label class="text-2xl font-bold">{{'credits.header' | translate}}</label>
        </div>
    </p-header>
    <div class="grid" *ngIf="Operator != null">
        <div class="col-12">
            <label for="" class="text-base">Si deseas <strong>comprar o retirar créditos</strong>, por favor comunícate con un <strong>Agente</strong>.</label>
        </div>
        <div class="col-12">
            <div class="flex flex-column">
                <label class="text-base font-bold" htmlFor="username">{{'credits.credits_modal' | translate}}</label>
                <label class="label-credit mt-1 p-1"><p class="text-white text-base">{{ Operator.data.sellCreditValue | creditsFormatter:{currencyCode, currencySymbol, credit: false} }}</p></label>
            </div>
        </div>
        <div class="col-12 mt-2">
            <label class="text-base">Puedes utilizar los siguientes <strong>caneles de comunicación:</strong></label>
        </div>
        <div class="col-12">
            <div class="flex flex-column">
                <label class="text-base font-bold">{{'credits.email_modal' | translate}}</label>
                <label class="label-credit mt-1  p-1"><p class="text-white text-base">{{Operator.data.contactEmail}}</p></label>
            </div>
        </div>
        <div class="col-12">
            <div class="flex flex-column">
                <label class="text-base font-bold">{{'credits.phone_modal' | translate}}</label>
                <label class="label-credit mt-1 p-1"><p class="text-white text-base">{{Operator.data.countryCode.ladaCode}} {{Operator.data.contactPhone}}</p></label>
                <p (click)="openSocialMediaDialog()" class="text-blue-500 mt-1" style="text-decoration: underline;cursor: pointer;">{{'credits.metodos_modal'| translate}}</p>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog appendTo="body" [style]="{width: '300px'}" [modal]="true" [draggable]="false" [(visible)]="IsSocialMediaVisible" (onHide)="closeSocialMediaDialog()">
    <p-header>
        <div style="justify-content: center;flex-direction: column;display: flex; align-items: start;">
            <label class="text-xl">{{'credits.social_Media_Dialog' | translate}}</label>
        </div>
    </p-header>
    <div class="grid">
        <div class="col-12">
            <label class="text-base">{{'credits.social_Media_Pago'| translate}}</label>
        </div>
        <div class="col-12">
            <div class="flex" *ngIf="isVisibleWhatsApp">
                <a [href]="'https://wa.me/'+NumberContact" target=”_blank” class="text-base text-white" style="cursor: pointer;"><i class="pi pi-whatsapp text-white"></i> {{'credits.social_Media_WhatsApp' | translate}}</a>
            </div>
        </div>
        <div class="col-12">
          <div class="flex" *ngIf="isVisibleViber">
                <a [href]="'https://msng.link/o/?'+NumberContact+'=vi'" target=”_blank”  class="text-base text-white" style="cursor: pointer;"><i class="pi pi-phone text-white"></i> {{'credits.social_Media_Viber' | translate}}</a>
            </div>
        </div>
        <div class="col-12">
            <div class="flex justify-content-end">
                <p-button label="Ok" styleClass="p-button-outlined" (onClick)="closeSocialMediaDialog()"><i class="pi pi-check pr-1"></i></p-button>
            </div>
        </div>
    </div>
</p-dialog>
