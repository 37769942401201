import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApiCore } from 'src/app/config/api';
import { ApiService } from 'src/app/core/http/api.service';
import { IOperatorConfig } from 'src/app/models/Profile/OperatorInterfaces';
import { I18nService } from 'src/app/shared/services/i18n.service';

@Component({
  selector: 'app-credits-info-component',
  templateUrl: './credits-info-component.component.html',
  styleUrls: ['./credits-info-component.component.css']
})
export class CreditsInfoComponentComponent implements OnInit {

  @Input() showCreditsDialog: boolean = false;
  @Output() onCloseCreditsDialog = new EventEmitter<boolean>();
  public IsSocialMediaVisible = false;
  public NumberContact: string = '';
  public Operator: any;
  public isVisibleWhatsApp: boolean = false;
  public isVisibleViber: boolean = false;
  public currencyCode: string = '';
  public currencySymbol: string = '';

  constructor(private api: ApiService,
              private i18nService: I18nService,
              private translate: TranslateService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.i18nService.localeEvent.subscribe({
      next: () => { this.useTranslate()}
    })

    if(!this.Operator)
    {
      this.GetoperatorData();
    }
  }

  public useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  openSocialMediaDialog(){
    this.IsSocialMediaVisible = true;
    if(!this.Operator){
      this.NumberContact = '';
    }
    this.NumberContact = this.Operator.data.contactPhone;
  }

  closeSocialMediaDialog(){
    this.IsSocialMediaVisible = false;
    this.NumberContact = '';
  }

  closeBuyCreditsDialog(){
    this.onCloseCreditsDialog.emit(false);
  }

  GetoperatorData(){
    this.api.list(`${ApiCore.OPERATOR}/operator-config`).subscribe((responseOperator)=>{
      if(responseOperator.statusCode != 200)
      {

        this.messageService.add({key: 'bc',severity: 'error', summary: 'Error loading data' });
        return;
      }
      //console.log(responseOperator.data.mesaggingServices)
      responseOperator.data.mesaggingServices.forEach((element: { messagingService: { name: string; }; name: any; }) => {
        if(element.messagingService.name == "Viber")
          this.isVisibleViber = true;
        if(element.messagingService.name == "WhatsApp")
          this.isVisibleWhatsApp = true;
      });
      this.Operator = responseOperator
      this.currencyCode = responseOperator.data.currencyCode;
      this.currencySymbol = responseOperator.data.currencySymbol;
    },(error) => {
      this.messageService.add({key: 'bc',severity: 'error', summary: JSON.stringify(error) });
    })
  }

}
