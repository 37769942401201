<p-toast position="top-center" key="tc"></p-toast>
<form [formGroup]="sumbmitForm">
    <div style="display: flex; width: 100%; height:100%; justify-content: center; justify-items: center; padding-top: 60px;" >
        <div style="flex-direction: column; justify-content: center;  width: 100%; display: flex; align-items: center;">
            <img [src]="operatorLogo" style= 'width: 300px; height: 90px; cursor: pointer;' [routerLink]="['/']">
            <label style="margin-top: 20px;" class="header_title" > {{'signin.title' | translate}} </label>
            <label style="margin-top: 20px;" class="header_subtitle"> {{'signin.subTitle' | translate}} </label>
            <div class="float_div">
                <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'signin.email' | translate}}</span>
                        <input (input)="validateUsedMail()" autocomplete="off" (blur)="validateUsedMail()" formControlName="email" style="margin-top: 5px;"  type="email" class="p-inputtext" placeholder="{{'signin.email_placeholder' | translate}}" pInputText />
                        <small style="color: #FC6161 ;" *ngIf="sumbmitForm.get('email').touched && sumbmitForm.get('email').errors && sumbmitForm.get('email').value != ''" class="p-invalid">Introduce una dirección de correo válida.</small>
                        <small style="color: #FC6161 ;" *ngIf="messageMailError != ''" class="p-invalid">{{messageMailError}}</small>
                    </div>
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'signin.user' | translate}}</span>
                        <input (focus)="messageUserNameError = ''" (blur)="validateUsedUserName()" formControlName="userName" maxlength="16" minlength="5" style="margin-top: 5px;"  type="text" class="p-inputtext" placeholder="{{'signin.user_placeholder' | translate}}" pInputText />
                        <small style="color: #FC6161 ;" *ngIf="messageUserNameError != '' && sumbmitForm.get('userName').value != ''" class="p-invalid">{{messageUserNameError}}</small>
                    </div>
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'signin.password' | translate}}</span>
                        <span class="p-input-icon-right">
                          <span class="p-input-icon-left">
                            <i ngClass="pi-key" class="pi" style="margin-top: -12px;"></i>
                          </span>
                          <i [ngClass]="!showPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showPassword = !showPassword" style="cursor:pointer"></i>
                          <input style="padding: 0.429rem 2.2rem;" (input)="validatePassword()" (blur)="validatePassword()" maxlength="16" formControlName="password" [type]="showPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'signin.password_placeholder' | translate}}">
                        </span>
                        <small style="color: #FC6161 ;" *ngIf="sumbmitForm.get('password').errors" class="p-invalid">{{messagePasswordError}}</small>
                    </div>
                    <div style="width: 90%; display: flex; flex-direction: column;">
                        <label style="font-size: 12px; color: gainsboro;" > {{'errors.min8' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;" > {{'errors.min1' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;" > {{'errors.minUpper' | translate}}</label>
                        <label style="font-size: 12px; color: gainsboro;" > {{'errors.minLow' | translate}}</label>
                    </div>
                    <div style="display: flex; flex-direction: column;  width: 90%;">
                        <span style="margin-top: 20px; font-size: 15px; font-weight: bold;">{{'signin.confirmPassword' | translate}}</span>
                        <span class="p-input-icon-right">
                          <span class="p-input-icon-left">
                            <i ngClass="pi-key" class="pi" style="margin-top: -12px;"></i>
                          </span>
                          <i [ngClass]="!showConfirmPassword ? 'pi-eye' : 'pi-eye-slash'" class="pi" (click)="showConfirmPassword = !showConfirmPassword" style="cursor:pointer"></i>
                          <input (input)="validateSamePassword()" style="padding: 0.429rem 2.2rem;" ((blur)="validateSamePassword()" maxlength="16" formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'" autocomplete="off" pInputText placeholder="{{'signin.confirmPassword_placeholder' | translate}}">
                        </span>
                        <small style="color: #FC6161 ;" *ngIf="!isSamePassword &&  sumbmitForm.get('confirmPassword').value != ''" class="p-invalid">Tu contraseña no es igual</small>
                    </div>
                    <div style="display: flex; width: 90%; margin-top: 10px;" >
                        <p-checkbox [binary]="true" formControlName="confirmAge" (onChange)="checkConfirmAge()"></p-checkbox>
                        <label style="margin-left: 10px;" > {{'signin.confirm' | translate}} </label>
                    </div>
                    <div style="display: flex; width: 90%; margin-top: 10px;" >
                        <p-checkbox [binary]="true" formControlName="confirmAcceptTerms" (onChange)="checkConfirmAcceptTerms()"></p-checkbox>
                        <label style="margin-left: 10px;" > {{'signin.allow' | translate}}</label>
                        <a (click)="showTermsConditions = true"  style="margin-left: 5px; cursor: pointer;" class="flex underline_link">{{'signin.privacity' | translate}}</a>
                        <label style="margin-left: 5px;">{{'common.and' | translate}}</label>
                        <a (click)="showPrivacyPolicy = true" style="margin-left: 5px; cursor: pointer;" class="flex underline_link">{{'signin.terms_coditions' | translate}}</a>
                    </div>
                    <button type="button" style="margin-top:20px; margin-bottom: 30px; width: 90%;" pButton label="{{'signin.crate_account' | translate}}" (click)="submit()" [disabled]="sumbmitForm.invalid || !checkConfAge || !checkConfTerm || isExistError || !isValidUser || !isValidMail"></button>
                </div>
            </div>
            <a style="margin-top: 20px; font-size: 15px;" routerLink="../login" class="flex underline_link">{{'signin.exist_account' | translate}}</a>
        </div>
    </div>
</form>
<app-terms-conditions [DisplaySideBar]="showTermsConditions" (onCloseSideBar)="showTermsConditions = $event"></app-terms-conditions>
<app-privacy-policy [DisplaySideBar]="showPrivacyPolicy" (onCloseSideBar)="showPrivacyPolicy = $event"></app-privacy-policy>
